import { get } from './http'
import request from './request'

export const csrfToken = () => {
  const url = '/token/csrf'
  return get(url)
}

export const refreshToken = (params) => {
  return request({
    url: '/token/refresh',
    params
  })
}
