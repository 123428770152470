export default {
  namespaced: true,
  state: () => ({
    subjectData: '',
    chapterData: {}
  }),
  mutations: {
    setSubjectData(state, data) {
      state.subjectData = data
    },
    setChapterData(state, data) {
      state.chapterData[data.id] = data.data
    },
    deleteData(state) {
      state.subjectData = ''
      state.chapterData = {}
    }
  }
}
