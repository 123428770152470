export default {
  namespaced: true,
  state: () => ({
    provinceData: '',
    cityData: {},
    areaData: {}
  }),
  mutations: {
    setProvinceData(state, data) {
      state.provinceData = data
    },
    setCityData(state, data) {
      state.cityData[data.id] = data.data
    },
    setAreaData(state, data) {
      state.areaData[data.id] = data.data
    }
  }
}
