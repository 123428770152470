export default {
  namespaced: true,
  state: () => ({
    profile: '',
    profileDetail: ''
  }),
  mutations: {
    setProfile(state, data) {
      state.profile = data
    },
    setProfileDetail(state, data) {
      state.profileDetail = data
    }
  }
}
