export default {
  token: (state) => state.app.token,
  chapterReturn: (state) => state.app.chapterReturn,

  statisticsData: (state) => {
    return (id) => {
      return state.statistics.data[id]
    }
  },

  subjectData: (state) => {
    return (id) => {
      return state.subject.data[id]
    }
  },

  homeData: (state) => state.home.data,

  searchHot: (state) => state.search.hotSearch,
  searchUser: (state) => state.search.userSearch,

  meProfile: (state) => state.me.profile,
  meProfileDetail: (state) => state.me.profileDetail,

  creditsIndexData: (state) => state.credits.indexData,

  courseSubjectData: (state) => state.course.subjectData,
  courseChapterData: (state) => {
    return (id) => {
      return state.course.chapterData[id]
    }
  },

  districtProvinceData: (state) => state.district.provinceData,
  districtCityData: (state) => {
    return (id) => {
      return state.district.cityData[id]
    }
  },
  districtAreaData: (state) => {
    return (id) => {
      return state.district.areaData[id]
    }
  }
}
