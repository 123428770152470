import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '超级课堂-登录' },
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: { title: '超级课堂-注册' },
    component: () => import('@/views/login/register.vue')
  },
  {
    path: '/forget',
    name: 'forget',
    meta: { title: '超级课堂-忘记密码' },
    component: () => import('@/views/login/forget.vue')
  },
  {
    path: '/subject/:id',
    name: 'subject',
    meta: { title: '超级课堂-课程列表' },
    component: () => import('@/views/subject/index.vue')
  },
  {
    path: '/chapter/:id',
    name: 'chapter',
    meta: { title: '超级课堂-课程播放' },
    component: () => import('@/views/chapter/index.vue')
  },
  {
    path: '/chapter/info/:id',
    name: 'chapter-info',
    meta: { title: '超级课堂-课程详情' },
    component: () => import('@/views/chapter/info.vue')
  },
  {
    path: '/practice/:id',
    name: 'practice',
    meta: { title: '超级课堂-习题' },
    component: () => import('@/views/chapter/practice.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    meta: { title: '超级课堂-设置' },
    component: () => import('@/views/me/setting.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { title: '超级课堂-个人信息' },
    component: () => import('@/views/me/profile.vue')
  },
  {
    path: '/message',
    name: 'message',
    meta: { title: '超级课堂-消息中心' },
    component: () => import('@/views/me/message.vue')
  },
  {
    path: '/order/message',
    name: 'order-message',
    meta: { title: '超级课堂-订单消息' },
    component: () => import('@/views/me/order-message.vue')
  },
  {
    path: '/order/:type',
    name: 'order',
    meta: { title: '超级课堂-我的订单' },
    component: () => import('@/views/order/order.vue')
  },
  {
    path: '/order/info/:id',
    name: 'order-info',
    meta: { title: '超级课堂-订单详情' },
    component: () => import('@/views/order/order-info.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    meta: { title: '超级课堂-购物车' },
    component: () => import('@/views/order/cart.vue')
  },
  {
    path: '/search',
    name: 'search',
    meta: { title: '超级课堂-搜索' },
    component: () => import('@/views/home/search.vue')
  },
  {
    path: '/package',
    name: 'package',
    meta: { title: '超级课堂-套餐列表' },
    component: () => import('@/views/package/index.vue')
  },
  {
    path: '/safe',
    name: 'safe',
    meta: { title: '超级课堂-账户安全' },
    component: () => import('@/views/me/safe.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: '超级课堂-关于我们' },
    component: () => import('@/views/me/about.vue')
  },
  {
    path: '/protocol',
    name: 'protocol',
    meta: { title: '超级课堂-使用协议' },
    component: () => import('@/views/me/protocol.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    meta: { title: '超级课堂-隐私保护政策' },
    component: () => import('@/views/me/policy.vue')
  },
  {
    path: '/suggest',
    name: 'suggest',
    meta: { title: '超级课堂-建议反馈' },
    component: () => import('@/views/me/suggest.vue')
  },
  {
    path: '/credits',
    name: 'credits',
    meta: { title: '超级课堂-积分商城' },
    component: () => import('@/views/other/credits.vue')
  },
  {
    path: '/credits/product',
    name: 'credits-product',
    meta: { title: '超级课堂-积分商品' },
    component: () => import('@/views/other/product.vue')
  },
  {
    path: '/credits/address',
    name: 'credits-address',
    meta: { title: '超级课堂-收货地址管理' },
    component: () => import('@/views/other/address.vue')
  },
  {
    path: '/credits/collection',
    name: 'credits-collection',
    meta: { title: '超级课堂-我的收藏' },
    component: () => import('@/views/other/collection.vue')
  },
  {
    path: '/product/info/:id',
    name: 'product-info',
    meta: { title: '超级课堂-商品详情' },
    component: () => import('@/views/other/product-info.vue')
  },
  {
    path: '/credits/log',
    name: 'credits-log',
    meta: { title: '超级课堂-积分明细' },
    component: () => import('@/views/other/credits-log.vue')
  },
  {
    path: '/course/history',
    name: 'course-history',
    meta: { title: '超级课堂-观看记录' },
    component: () => import('@/views/course/history.vue')
  },
  {
    path: '/credits/order',
    name: 'credits-order',
    meta: { title: '超级课堂-兑换记录' },
    component: () => import('@/views/other/credits-order.vue')
  },
  {
    path: '/task',
    name: 'task',
    meta: { title: '超级课堂-任务中心' },
    component: () => import('@/views/other/task.vue')
  },
  {
    path: '/comment',
    name: 'comment',
    meta: { title: '超级课堂-我的评论' },
    component: () => import('@/views/me/comment.vue')
  },
  {
    path: '/charge',
    name: 'charge',
    meta: { title: '超级课堂-超级币充值' },
    component: () => import('@/views/charge/index.vue')
  },
  {
    path: '/charge/online',
    name: 'charge-online',
    meta: { title: '超级课堂-在线充值' },
    component: () => import('@/views/charge/online.vue')
  },
  {
    path: '/charge/card',
    name: 'charge-card',
    meta: { title: '超级课堂-学习卡充值' },
    component: () => import('@/views/charge/card.vue')
  },
  {
    path: '/charge/log',
    name: 'charge-log',
    meta: { title: '超级课堂-充值记录' },
    component: () => import('@/views/charge/log.vue')
  },
  {
    path: '/package/info/:id',
    name: 'package-info',
    meta: { title: '超级课堂-套餐详情' },
    component: () => import('@/views/package/package-info.vue')
  },
  {
    path: '/question',
    name: 'question',
    meta: { title: '超级课堂-我的题库' },
    component: () => import('@/views/question/index.vue')
  },
  {
    path: '/question/detail/:id',
    name: 'question-detail',
    meta: { title: '超级课堂-我的题库' },
    component: () => import('@/views/question/detail.vue')
  },
  {
    path: '/share',
    name: 'share',
    meta: { title: '超级课堂-分享邀请码' },
    component: () => import('@/views/home/share.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: { title: '超级课堂' },
    component: () => import('../views/layout'),
    redirect: 'index',
    children: [
      {
        path: '/',
        name: 'index',
        meta: { title: '超级课堂' },
        component: () => import('@/views/home/index.vue')
      },

      {
        path: 'course',
        name: 'course',
        meta: { title: '超级课堂-我的课程' },
        component: () => import('@/views/course/index.vue')
      },
      {
        path: 'statistics',
        name: 'statistics',
        meta: { title: '超级课堂-学习轨迹' },
        component: () => import('@/views/statistics/index.vue')
      },
      {
        path: 'me',
        name: 'me',
        meta: { title: '超级课堂-个人中心' },
        component: () => import('@/views/me/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
