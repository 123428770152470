export default {
  namespaced: true,
  state: () => ({
    indexData: ''
  }),
  mutations: {
    setIndexData(state, data) {
      state.indexData = data
    },
    updateIndexData(state, data) {
      state.indexData[data.id] = data.data
    }
  }
}
