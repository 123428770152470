import router from './index'
import store from '@/store'

const whiteList = [
  'login',
  'index',
  'subject',
  'chapter',
  'register',
  'protocol',
  'policy',
  'forget'
]
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    window.document.title = to.meta.title
  } else {
    window.document.title = '超级课堂'
  }
  if (store.getters.token) {
    if (to.name === 'login') {
      next('/')
    } else {
      next()
    }
  } else {
    if (whiteList.includes(to.name)) {
      next()
    } else {
      next('/login')
    }
  }
})
