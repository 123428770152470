export default {
  namespaced: true,
  state: () => ({
    data: {}
  }),
  mutations: {
    setData(state, data) {
      state.data[data.id] = data.data
    },
    deleteData(state) {
      state.data = {}
    }
  }
}
