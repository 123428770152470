export default {
  namespaced: true,
  state: () => ({
    hotSearch: JSON.parse(localStorage.getItem('hotSearch')) || '',
    userSearch: localStorage.getItem('userSearch')
      ? JSON.parse(localStorage.getItem('userSearch'))
      : ''
  }),
  mutations: {
    setHotSearch(state, data) {
      state.hotSearch = data
      localStorage.setItem('hotSearch', JSON.stringify(data))
    },
    setUserSearch(state, data) {
      state.userSearch = data
      localStorage.setItem('userSearch', JSON.stringify(data))
    },
    deleteUserSearch(state) {
      state.userSearch = ''
      localStorage.setItem('userSearch', '')
    }
  }
}
