import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 8000
})
service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)
service.interceptors.response.use(
  (response) => {
    const { data, code, msg } = response.data
    const csrfToken = response.data.csrf_token
    if (response.config.method === 'post' && csrfToken !== undefined) {
      localStorage.setItem('csrfTokenTime', Date.now())
      localStorage.setItem('csrf_token', csrfToken)
    }
    if (response.config.url === '/token/refresh') {
      if (code === 40001) {
        store.dispatch('app/logout')
        ElMessage.error('请重新登录')
        return Promise.reject(new Error('请重新登录'))
      }
    }
    if (response.data.token_error) {
      store.dispatch('app/logout')
      ElMessage.error('请重新登录')
      return Promise.reject(new Error('请重新登录'))
    }
    if (code === 0) {
      return data
    } else if (
      code === 40004 ||
      code === 40000 ||
      code === 40002 ||
      code === 40003 ||
      code === 40000 ||
      code === 40005 ||
      code === 40011
    ) {
      store.dispatch('app/logout')
      ElMessage.error('请重新登录')
      return Promise.reject(new Error('请重新登录'))
    } else if (code === 50000) {
      console.log(response)
    } else if (code === 50001) {
      return Promise.reject(new Error(code))
    } else {
      ElMessage.error(msg)
      return Promise.reject(new Error(msg))
    }
  },
  (error) => {
    error.response && ElMessage.error('请求失败')
    return Promise.reject(new Error('请求失败'))
  }
)

export default service
