import { createStore } from 'vuex'
import app from './modules/app'
import statistics from './modules/statistics'
import home from './modules/home'
import me from './modules/me'
import subject from './modules/subject'
import course from './modules/course'
import district from './modules/district'
import search from './modules/search'
import credits from './modules/credits'
import getters from './getters'

export default createStore({
  getters,
  modules: {
    app,
    home,
    me,
    subject,
    district,
    search,
    credits,
    course,
    statistics
  }
})
