import { post } from './http'

export const login = (data) => {
  const url = '/auth/login'
  return post(url, data)
}

export const register = (data) => {
  const url = '/auth/reg'
  return post(url, data)
}

export const getCode = (data) => {
  const url = '/auth/sms_code/reg'
  return post(url, data)
}

export const forgetCode = (data) => {
  const url = '/auth/sms_code/forgot'
  return post(url, data)
}

export const changePassword = (data) => {
  const url = '/auth/change_pass'
  return post(url, data)
}
