import { login as loginApi, register as registerApi } from '@/api/login'
import router from '@/router'
import { setTokenTime } from '@/util/auth'
import { clear } from '@/util/cache'

export default {
  namespaced: true,
  state: () => ({
    token: localStorage.getItem('token') || '',
    chapterReturn: localStorage.getItem('chapterReturn') || ''
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    setChapterReturn(state, chapterReturn) {
      state.chapterReturn = chapterReturn
      localStorage.setItem('chapterReturn', chapterReturn)
    }
  },
  actions: {
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginApi(userInfo)
          .then((res) => {
            commit('setToken', res.token)
            clear()
            setTokenTime()
            router.replace('/')
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    register({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        registerApi(userInfo)
          .then((res) => {
            commit('setToken', res.token)
            clear()
            setTokenTime()
            router.replace('/')
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    logout({ commit }) {
      commit('setToken', '')
      clear()
      localStorage.clear()
      router.replace({ name: 'login' })
    }
  }
}
