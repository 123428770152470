import request from './request'
import QS from 'qs'
import {
  csrfToken as csrfTokenApi,
  refreshToken as refreshTokenApi
} from './common'
import { diffTokenTime, setTokenTime, diffCsrfTokenTime } from '@/util/auth'
import store from '@/store'

export function post(url, metadata) {
  const data = { ...metadata }
  if (data.csrf_token === undefined) {
    let csrfToken = localStorage.getItem('csrf_token') || ''
    if (diffCsrfTokenTime() || csrfToken === '') {
      const getCsrfToken = async (url, data) => {
        const csrfTokenData = await csrfTokenApi()
        csrfToken = csrfTokenData.csrf_token
        data.csrf_token = csrfToken
        return doPost(url, data)
      }
      return getCsrfToken(url, data)
    } else {
      data.csrf_token = csrfToken
      localStorage.setItem('csrf_token', '')
      return doPost(url, data)
    }
  } else {
    return doPost(url, data)
  }
}

const doPost = async (url, data) => {
  data = await addToken(data)
  return request({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url,
    data: QS.stringify(data)
  })
}

const addToken = (metadata) => {
  const data = { ...metadata }
  if (data.token === undefined) {
    if (localStorage.getItem('token')) {
      if (diffTokenTime()) {
        const refreshToken = async (data) => {
          const tokenData = await refreshTokenApi({
            token: localStorage.getItem('token')
          })
          setTokenTime()
          store.commit('app/setToken', tokenData.token)
          data.token = tokenData.token
          return data
        }
        return refreshToken(data)
      } else {
        data.token = localStorage.getItem('token')
        return data
      }
    } else {
      return data
    }
  }
}

export async function get(url, params = {}) {
  params = await addToken(params)
  return request({ url, params })
}
