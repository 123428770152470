import store from '@/store'
import { toRaw } from '@vue/reactivity'

export const clear = () => {
  store.commit('home/setData', '')
  store.commit('course/deleteData')
  store.commit('statistics/deleteData')
  store.commit('subject/deleteData')
  store.commit('me/setProfile', '')
  store.commit('me/setProfileDetail', '')
}

export const updateProfileCache = (k, v) => {
  const profile = toRaw(store.getters.meProfile) || ''
  const profileDetail = toRaw(store.getters.meProfileDetail) || ''
  if (profile && profile[k]) {
    profile[k] = v
  }
  if (profileDetail && profileDetail.user[k]) {
    profileDetail.user[k] = v
  }
  store.commit('me/setProfile', profile)
  store.commit('me/setProfileDetail', profileDetail)
  return true
}
